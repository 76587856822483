.container {
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: fit-content;
}

.container.sm {
  padding: 4px;
}

.container.md {
  padding: 8px;
}

.container.lg {
  padding: 12px 8px;
}

.container.xl {
  padding: 16px 8px;
}

.label {
  font-size: 14px;
  white-space: nowrap;
}

.label.wrapText {
  white-space: wrap;
}
